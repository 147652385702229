import { set, toggle } from '@/utils/vuex'

export default {
  namespaced: true,

  state: {
    links: [['/', 'Home'], ['/projects', 'Projects'], ['/team', 'Team'], ['/about', 'About']],
    drawer: null
  },

  mutations: {
    setDrawer: set('drawer'),
    toggleDrawer: toggle('drawer')
  }
}
