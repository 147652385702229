<template>
  <v-app dark>
    <core-toolbar />

    <core-drawer />

    <router-view />

    <core-footer />
  </v-app>
</template>

<script>
  export default {
    components: {
      CoreDrawer: () => import('@/components/core/Drawer'),
      CoreFooter: () => import('@/components/core/Footer'),
      CoreToolbar: () => import('@/components/core/Toolbar')
    }
  }
</script>

<style>
  .application {
    background: #C9C9C9 !important;
  }
  #hero h1 {
    letter-spacing: 4px !important;
  }
</style>
