export default {
  namespaced: true,

  state: {
    posts: [
      {
        title: 'The Black Dahlia Tattoo',
        date: 'February 2020',
        author: 'https://www.tattoobeograd.rs',
        body: `
          <p>Nice presentational web page with contact form.</p>

          <p>Emphasis is on the call to action sections throught the presentation. Video background and artist based gallery.</p>

          <p>SEO optimization.</p>
        `,
        tags: ['Presentation', 'Contact Form'],
        more: '/posts/tattoo',
        slug: 'tattoo',
        image: 'tattoo.jpg'
      },
      {
        title: 'Vijci Kranjec',
        date: 'December 2019',
        author: 'https://vijci.com',
        body: `
          <p>Tailor-made Web Shop built from scratch with Laravel as back-end, Vue as front-end  and connected to existing ERP.</p>

          <p>It was made mainly for contractors and construction workers for wholesale hardware products shop. It has UX with emphasis on fast and intuitive ordering of large quantities of different products.</p>

          <p>It has very powerful search engine. Both product stock and pricing policy are imported from ERP. Advanced pricing policy depending on customer/partner affiliation level up to five levels deep, updated in real time. Product grouping is four levels deep linked to search engine, for easy use.</p>

          <p>SOLID development principles and SEO optimization were applied.</p>
        `,
        tags: ['E-commerce Development', 'Web Shop'],
        more: '/posts/vijci',
        slug: 'vijci',
        image: 'vijci.jpg'
      },
      {
        title: 'Danaa.app - Proof of concept',
        date: 'September 2019',
        author: 'https://user.onbagheera.com/',
        body: `
          <p>Business and event directory with powerful admin panel. Front-end work on Vue+Vuetify.</p>

          <p>Idea is to make traveling to certain city and exploring it easy to do. Also, residents can find more easily businesses and services in town.</p>

          <p>On admin panel business owners can create a profile with all relevant data, apply categories...Users can search businesses by categories/city, and follow events.</p>

          <p>Custom design, API from Swagger file.</p>
        `,
        tags: ['Business directory', 'Traveling'],
        more: '/posts/danaa',
        slug: 'danaa',
        image: 'danaa.jpg'
      },
      {
        title: 'Nautika Kranjec',
        date: 'October 2018',
        author: 'https://nautika.hr',
        body: `
          <p>Tailor-made Web Shop built from scratch with Laravel as back-end, Vue as front-end and connected to existing ERP.</p>

          <p>It is for nautical equipment shop. It has clear design and high-quality product presentation.</p>

          <p>It has very powerful search engine. Both product stock and pricing policy are imported from ERP. Advanced pricing policy depending on customer/partner affiliation level up to five levels deep, updated in real time. Product grouping is four levels deep linked to search engine, for easy use.</p>

          <p>SOLID development principles and SEO optimization were applied.</p>
        `,
        tags: ['E-commerce Development', 'Web Shop'],
        more: '/posts/nautika',
        slug: 'nautika',
        image: 'nautika.jpg'
      },
      {
        title: 'Selenium Automation',
        date: 'June 2018',
        author: '',
        body: `
          <p>Task was to make automation/scraping script which is able to edit large number of records trough web application form with login.</p>

          <p>Python and Selenium were used to achieve that successfully.</p>
        `,
        tags: ['Data Scraping', 'Web Scraping'],
        more: '/posts/selenium',
        slug: 'selenium'
      },
      {
        title: 'IoT Software/Hardware Platform',
        date: 'May 2018',
        author: 'https://agro.stronganic.rs/',
        body: `
          <p>Fully functional IoT system using latest technologies.</p>

          <p>Main target was allowing real-time data collection and controlling remote access using web/mobile applications. Focus was on security and robustness of the whole system.</p>

          <p>Both, software and hardware were developed in the house. Software can be used with any hardware micro controllers especially with Raspberry Pi and various Arduino platforms.</p>
        `,
        tags: ['IoT', 'Networking', 'MySQL Programming'],
        more: '/posts/stronganic',
        slug: 'stronganic'
      }
    ]
  }
}
