<template>
  <v-img
    :src="src"
    :gradient="gradient"
    :height="height"
  >
    <slot />
  </v-img>
</template>

<script>
  export default {
    props: {
      gradient: {
        type: String,
        default: 'to top, rgba(0,0,0,.05), rgba(0,0,0,.40)'
      },
      height: {
        type: [Number, String],
        default: '30vh'
      },
      src: {
        type: String,
        default: undefined,
        required: true
      }
    }
  }
</script>
