import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { Scroll } from 'vuetify/lib/directives'
import 'vuetify/src/stylus/app.styl'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify, {
  theme: {
    primary: '#F44336',
    secondary: '#2B2D42',
    accent: '#8D99AE'
  },
  iconfont: 'mdi',
  directives: {
    Scroll
  }
})
